import React, { useState, useEffect } from 'react';
import {
  Button,
  ConfigProvider,
  Form,
  InputNumber,
  Popover,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import { VERSION_1, VERSION_2 } from '@src/utils/constants';
import './index.less';
import { isNumber } from 'lodash';
import TagIssueState from '../RuleEngine/modules/DryRun/TagIssueState';
import { Icon } from 'antdV3';
import {
  DryRunReportCompareDetailV1,
  DryRunReportCompareDetail,
} from './DryRunReportDetail';
import {
  requestDryRunReportV1,
  requestDryRunReportV2,
  useRequestDryRunList,
} from '@src/service/api';
import { getNameByDryRunInfo } from '@src/utils/ruleEngine';
import * as Api from '../../service/api';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  DryRunReportFieldDetail,
  DryRunReportFieldDetailV1,
} from '../RuleEngine/modules/DryRun/DryRunReportFieldDetail';
import {
  updateStateDryRunContent,
  updateStateDryRunOptions1,
  updateStateDryRunOptions2,
} from '@src/store/actions/dryRunCompare';
import { dryRunContentItem, ruleVersionName } from '@src/typing/dryRunCompare';
import { updateDryRunCompareLists } from '@src/store/actions/ruleEngine';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const replaceRouteUrl = ({
  businessParcelId,
  ruleId1,
  dryRunId1,
  ruleId2,
  dryRunId2,
}) => {
  return `/dry-run-compare?businessParcelId=${businessParcelId}&ruleId1=${ruleId1}&dryRunId1=${dryRunId1}&ruleId2=${ruleId2}&dryRunId2=${dryRunId2}`;
};

const DryRunCompare = (props: any) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [defaultCoverage, setDefaultCoverage] = useState(80);
  const [isSame, setIsSame] = useState(false);

  let dryRunList: any[] = [];
  const store = useAppSelector((state) => {
    return {
      ruleEngine: state.ruleEngine,
      dryRunCompare: state.dryRunCompare,
    };
  });

  const { dryRunCompare } = store;
  let { ruleVersions, activeBusinessParcel, activeRule, dryRunCompareLists } =
    store.ruleEngine;
  const { dryRunContentData, dryRunOption1, dryRunOption2 } = dryRunCompare;
  const dryRunCompareListItem = dryRunCompareLists[0];

  const ruleTitle = activeBusinessParcel.project?.projectName;
  // ruleVersions
  const ruleVersionOptions = ruleVersions.map((item) => {
    return (
      <Option
        data-test-id="version-list-item"
        value={item.uuid}
        key={item.uuid}
      >
        {ruleTitle} {item.versionNo}.0
      </Option>
    );
  });

  const { data: dryRunListInfo, isLoading } = useRequestDryRunList(
    activeBusinessParcel,
    activeRule,
  );
  if (dryRunListInfo) {
    dryRunList = dryRunListInfo.entities;
  }

  // dryRunOptions
  let dryRunOptionLists: ruleVersionName[] = [];
  const dryRunOptions = (dryRunList) => {
    dryRunOptionLists = dryRunList.map((item) => {
      const canCompare =
        item.jobStatus === 'SUCCESS' && item.effective === true;
      const state = item.effective === false ? 'Not Valid' : item.jobStatus;
      return {
        label: !canCompare
          ? getNameByDryRunInfo(item) + '--' + state
          : getNameByDryRunInfo(item),
        value: item.uuid,
        disabled: !(item.jobStatus === 'SUCCESS' && item.effective === true),
        jobStatus: item.jobStatus,
        effective: item.effective,
      };
    });
    return dryRunOptionLists;
  };
  useEffect(() => {
    dryRunOptions(dryRunList);
  }, [dryRunList]);

  // dryRunList
  const requestDryRunList = async (ruleId) => {
    const { reVersion } = activeBusinessParcel;
    const projectCode = activeBusinessParcel.project.projectCode;
    if (reVersion === VERSION_1) {
      const res = await Api.requestDryRunList(VERSION_1, {
        params: {
          ruleId: ruleId,
        },
      });
      dryRunList = res.entities || [];
    } else {
      const res = await Api.requestDryRunList(VERSION_2, {
        params: {
          projectCode,
          ruleId: ruleId,
        },
      });
      dryRunList = res.entities || [];
    }
    return dryRunList;
  };

  // report
  const dryRunReport = async (dryRun) => {
    const { reVersion } = activeBusinessParcel;
    let dryRunReport;
    if (reVersion === 'V1') {
      dryRunReport = await requestDryRunReportV1({
        params: { dryRunId: dryRun },
      });
    } else {
      dryRunReport = await requestDryRunReportV2({
        urlParams: {
          dryRunId: dryRun,
        },
      });
    }
    return dryRunReport;
  };

  const updateStateDryRun = function () {
    const fromValue = form.getFieldsValue();
    const { name1, name2, version1, version2 } = fromValue;
    dispatch(
      updateDryRunCompareLists([
        {
          businessParcelId: activeBusinessParcel.uuid,
          ruleId1: version1,
          dryRunId1: name1,
          ruleId2: version2,
          dryRunId2: name2,
        },
      ]),
    );
    const replaceUrl = replaceRouteUrl({
      businessParcelId: activeBusinessParcel.uuid,
      ruleId1: version1,
      dryRunId1: name1,
      ruleId2: version2,
      dryRunId2: name2,
    });
    history.replace(replaceUrl);
  };

  const [dryRunContent, setDryRunContent] = useState<dryRunContentItem[]>([]);
  const [dryRunOptions1, setRunOptions1] = useState(dryRunOptionLists);
  const [dryRunOptions2, setRunOptions2] = useState(dryRunOptionLists);
  const [dryRunName1, setDryRunName1] = useState<string | undefined>(
    dryRunOptionLists[0]?.value,
  );
  const [dryRunName2, setDryRunName2] = useState<string | undefined>(
    dryRunOptionLists[0]?.value,
  );

  // default
  useEffect(() => {
    if (dryRunOption1.length) {
      setRunOptions1(dryRunOption1);
    } else {
      setRunOptions1(dryRunOptionLists);
      dispatch(updateStateDryRunOptions1({ dryRunOption1: dryRunOptionLists }));
    }
    if (dryRunOption2.length) {
      setRunOptions2(dryRunOption2);
    } else {
      setRunOptions2(dryRunOptionLists);
      dispatch(updateStateDryRunOptions2({ dryRunOption2: dryRunOptionLists }));
    }
    dryRunContentData?.length > 0 && setDryRunContent(dryRunContentData);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      version1: dryRunCompareListItem?.ruleId1,
      version2: dryRunCompareListItem?.ruleId2,
      name1: dryRunCompareListItem?.dryRunId1,
      name2: dryRunCompareListItem?.dryRunId2,
    });
  }, []);

  useEffect(() => {
    dispatch(
      updateStateDryRunContent({
        dryRunContentData: dryRunContent,
      }),
    );
  }, [dispatch, dryRunContent]);

  const changeRuleVersion1 = async (ruleId) => {
    setDryRunContent([]);
    let dryRun1List = await requestDryRunList(ruleId);
    const dryRunList1 = dryRunOptions(dryRun1List);
    const dryRun1SelectName = dryRunList1.find(
      (item) => item.jobStatus === 'SUCCESS' && item.effective,
    );
    setRunOptions1(dryRunList1);
    dispatch(updateStateDryRunOptions1({ dryRunOption1: dryRunList1 }));
    setDryRunName1(dryRun1SelectName?.value);
    form.setFieldsValue({ name1: dryRun1SelectName?.value });
    updateStateDryRun();
  };

  const changeRuleVersion2 = async (ruleId) => {
    setDryRunContent([]);
    let dryRun2List = await requestDryRunList(ruleId);
    const dryRunList2 = dryRunOptions(dryRun2List);
    const dryRun2SelectName = dryRunList2.find(
      (item) => item.jobStatus === 'SUCCESS' && item.effective,
    );
    setRunOptions2(dryRunList2);
    dispatch(updateStateDryRunOptions2({ dryRunOption2: dryRunList2 }));
    setDryRunName2(dryRun2SelectName?.value);
    form.setFieldsValue({ name2: dryRun2SelectName?.value });
    updateStateDryRun();
  };

  const changeDryRunOptions1 = async (dryRunId1) => {
    let dryRunList1 = await dryRunReport(dryRunId1);
    setDryRunName1(dryRunId1);
    updateStateDryRun();
    return dryRunList1;
  };

  const changeDryRunOptions2 = async (dryRunId2) => {
    let dryRunList2 = await dryRunReport(dryRunId2);
    setDryRunName2(dryRunName2);
    updateStateDryRun();
    return dryRunList2;
  };

  const onFinish = async (values: any) => {
    let { version1, version2, name1, name2 } = values;
    let list1: any = [];
    let list2: any = [];
    let dryRunReport1: any = [];
    let dryRunReport2: any = [];
    dryRunReport1 = await dryRunReport(name1);
    setDryRunName1(name1);
    dryRunReport2 = await dryRunReport(name2);
    setDryRunName2(name2);

    if (version1 === version2 && name1 === name2) {
      setIsSame(true);
      setDryRunContent([]);
    } else {
      setIsSame(false);

      dryRunReport1?.forEach((item: any) => {
        let index = dryRunReport2?.findIndex((v) => v.fieldId === item.fieldId);
        if (index > -1) {
          list1.push(item);
          list2.push(dryRunReport2[index]);
        } else {
          list1.push(item);
          list2.push({});
        }
      });
      dryRunReport2?.forEach((item: any) => {
        let index = dryRunReport1?.findIndex((v) => v.fieldId === item.fieldId);
        if (index === -1) {
          list1.push({});
          list2.push(item);
        }
      });

      list1?.forEach((item, index) => {
        if (item && list2[index])
          if (item.fieldId !== list2[index].fieldId) {
            if (item.fieldName === undefined) {
              item.fieldName = list2[index]?.fieldName;
            }
            item.fieldId = list2[index]?.fieldId;
          }
        item.coverage2 = list2[index]?.coverage;
        item.values2 = list2[index].values;
      });
      setDryRunContent(list1);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setDryRunContent([]);
  };

  const changeCoverage = (num) => {
    if (isNumber(num)) {
      setDefaultCoverage(num);
    }
  };

  const columns = [
    {
      key: 'fieldName',
      title: 'Metadata',
      dataIndex: 'fieldName',
    },
    {
      title: 'dry run 1',
      children: [
        {
          key: 'traffic',
          title: 'All Traffic',
          dataIndex: 'coverage',
          render: (text: number) => {
            if (text !== undefined) {
              return (
                <TagIssueState
                  type={text >= defaultCoverage ? 'Passed' : 'Not Passed'}
                />
              );
            } else {
              return <></>;
            }
          },
        },
      ],
    },
    {
      title: 'dry run 1',
      children: [
        {
          key: 'coverage',
          title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Valid Coverage</span>
              <Tooltip
                placement="rightTop"
                title="The coverage rate of expected data (UNKNOWN, null, N/A, undefined etc. are no included)"
              >
                <Icon
                  style={{ marginLeft: '10px' }}
                  type="info-circle"
                  theme="filled"
                />
              </Tooltip>
            </div>
          ),
          dataIndex: 'coverage',
          render: (text, record) => {
            if (text !== undefined) {
              return (
                <Popover
                  trigger="click"
                  placement="rightTop"
                  content={
                    Array.isArray(record.values) ? (
                      <DryRunReportFieldDetail dataSource={record.values} />
                    ) : (
                      <DryRunReportFieldDetailV1
                        fieldId={record.fieldId}
                        dryRunId={dryRunName1}
                      />
                    )
                  }
                >
                  <a href="!#">{text}%</a>
                </Popover>
              );
            } else {
              return <></>;
            }
          },
        },
      ],
    },
    {
      title: 'dry run 2',
      children: [
        {
          key: 'traffic',
          title: 'All Traffic',
          dataIndex: 'coverage2',
          render: (text: number) => {
            if (text !== undefined) {
              return (
                <TagIssueState
                  type={text >= defaultCoverage ? 'Passed' : 'Not Passed'}
                />
              );
            } else {
              return <></>;
            }
          },
        },
      ],
    },
    {
      title: 'dry run 2',
      children: [
        {
          key: 'coverage',
          title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Valid Coverage</span>
              <Tooltip
                placement="rightTop"
                title="The coverage rate of expected data (UNKNOWN, null, N/A, undefined etc. are no included)"
              >
                <Icon
                  style={{ marginLeft: '10px' }}
                  type="info-circle"
                  theme="filled"
                />
              </Tooltip>
            </div>
          ),
          dataIndex: 'coverage2',
          render: (text, record, index) => {
            if (text !== undefined) {
              return (
                <Popover
                  trigger="click"
                  placement="rightTop"
                  content={
                    Array.isArray(record.values2) ? (
                      <DryRunReportFieldDetail dataSource={record.values2} />
                    ) : (
                      <DryRunReportFieldDetailV1
                        fieldId={record.fieldId}
                        dryRunId={dryRunName2}
                      />
                    )
                  }
                >
                  <a href="!#">{text}%</a>
                </Popover>
              );
            } else {
              return <></>;
            }
          },
        },
      ],
    },
    {
      key: 'compare',
      title: 'Compare',
      dataIndex: 'compare',
      render: (text, record) => {
        if (record.coverage === undefined) {
          record.coverage = 0;
        }
        if (record.coverage2 === undefined) {
          record.coverage2 = 0;
        }
        if (record.fieldId === undefined) {
          record.fieldId = record.fieldName;
        }
        const coverageCompareResult = record.coverage2 - record.coverage;
        return (
          <Popover
            trigger="click"
            placement="leftBottom"
            content={
              Array.isArray(record.values) || Array.isArray(record.values2) ? (
                <DryRunReportCompareDetail
                  dataSource={{ list1: record.values, list2: record.values2 }}
                />
              ) : (
                <DryRunReportCompareDetailV1
                  fieldId={record.fieldId}
                  dryRunId1={dryRunName1}
                  dryRunId2={dryRunName2}
                />
              )
            }
          >
            {coverageCompareResult > 0 ? (
              <div className="compareResult">
                <CaretUpOutlined style={{ fontSize: '26px', color: 'red' }} />
                <span style={{ color: 'red' }}>
                  {coverageCompareResult.toFixed(2)}%
                </span>
              </div>
            ) : coverageCompareResult === 0 ? (
              <div className="compareResult">
                <MinusOutlined style={{ fontSize: '26px' }} />
              </div>
            ) : (
              <div className="compareResult">
                <CaretDownOutlined
                  style={{ fontSize: '26px', color: 'green' }}
                />{' '}
                <span style={{ color: 'green' }}>
                  {Math.abs(Number(coverageCompareResult.toFixed(2)))}%
                </span>
              </div>
            )}
          </Popover>
        );
      },
    },
  ];

  return (
    <ConfigProvider prefixCls="mds-ant">
      <Spin spinning={loading}>
        <div className="dryRun">
          <div className="form">
            <Form
              name="basic"
              form={form}
              initialValues={{
                version1: activeRule?.uuid,
                name1: dryRunName1,
                version2: activeRule?.uuid,
                name2: dryRunName2,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="dryRunDiv">
                <span className="span">Dry Run 1 :</span>
                <Form.Item
                  label="Rule Version"
                  name="version1"
                  style={{ marginRight: '100px' }}
                >
                  <Select style={{ width: 400 }} onChange={changeRuleVersion1}>
                    {ruleVersionOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name1"
                  // rules={[
                  //   {
                  //     validator(_, value) {
                  //       if (value.effective && value.jobStatus === 'SUCCESS') {
                  //         return Promise.resolve();
                  //       }
                  //       return Promise.reject(
                  //         new Error('no valid dry run report'),
                  //       );
                  //     },
                  //   },
                  // ]}
                >
                  <Select
                    style={{ width: 400 }}
                    placeholder="No data in this rule"
                    options={dryRunOptions1}
                    onChange={changeDryRunOptions1}
                  ></Select>
                </Form.Item>
              </div>
              <div className="dryRunDiv">
                <span className="span">Dry Run 2 :</span>
                <Form.Item
                  label="Rule Version"
                  name="version2"
                  style={{ marginRight: ' 100px' }}
                >
                  <Select style={{ width: 400 }} onChange={changeRuleVersion2}>
                    {ruleVersionOptions}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Name"
                  name="name2"
                  // rules={[
                  //   {
                  //     validator(_, value) {
                  //       if (value.effective && value.jobStatus === 'SUCCESS') {
                  //         return Promise.resolve();
                  //       }
                  //       return Promise.reject(
                  //         new Error('no valid dry run report'),
                  //       );
                  //     },
                  //   },
                  // ]}
                >
                  <Select
                    style={{ width: 400 }}
                    placeholder="No data in this rule"
                    options={dryRunOptions2}
                    onChange={changeDryRunOptions2}
                  ></Select>
                </Form.Item>
              </div>
              <div className="dryRunDiv" style={{ justifyContent: 'flex-end' }}>
                <Form.Item>
                  {isSame ? (
                    <span style={{ color: 'red' }}>
                      Please choose two different dry run reports compare
                    </span>
                  ) : null}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: '0 20px' }}
                  >
                    compare
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <InputNumber
              defaultValue={defaultCoverage}
              size="large"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              onChange={changeCoverage}
            />
            <div className="passed">
              <TagIssueState type="Passed" />
              <div>
                <small>Passed</small>{' '}
              </div>
            </div>
            <div className="passed">
              <TagIssueState type="Not Passed" />
              <div>
                <small>Coverage not passed</small>{' '}
              </div>
            </div>
          </div>
          <div className="tableWrapper">
            <Table
              loading={isLoading}
              pagination={false}
              columns={columns}
              dataSource={dryRunContent}
              rowKey={(record) => record.fieldName}
            />
          </div>
        </div>
      </Spin>
    </ConfigProvider>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.props === nextProps.props;
}

export default React.memo(DryRunCompare, areEqual);
